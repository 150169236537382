<template>
  <div class="content">

    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true"  size="mini" :model="search">
          <el-form-item label="录入日期">
             <el-date-picker
              v-model="search.sp_excellent_time"
              type="date"
              class="w130"
              clearable
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker>
            </el-form-item>
          
          <el-form-item label="客户名称">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              clearable
              class="w100"
              placeholder="请输入"
            />
          </el-form-item>
          
          <el-form-item label="位置">
            <el-select
              v-model="search.position_id"
              clearable
              class="w100"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options.position_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="原因">
            <el-select
              v-model="search.reason_id"
              clearable
              class="w120"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options.reason_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
           <el-form-item label="疤痕类型">
            <el-select
              v-model="search.type_id"
              clearable
              class="w120"
            >
              <el-option
                v-for="item in options.type_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="门店/门诊">
            <el-select
              v-model="search.shop_id"
              clearable
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shop_id"
                :label="item.shop_name"
                :value="item.shop_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="归属市场">
            <el-select
              v-model="search.market_id"
              clearable
              class="w120"
              placeholder="请选择统归属市场"
            >
              <el-option
                v-for="item in options.market_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select
              v-model="search.selection_results"
              clearable
              class="w120"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in options.selection_results"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="审核人">
            <el-input
              v-model="search.realname"
              maxlength="30"
              clearable
              class="w100"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="	审核时间">
             <el-date-picker
              v-model="search.tg_excellent_time"
              type="date"
              class="w130"
              clearable
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              >
            </el-date-picker>
            </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <el-button v-if="!userInfo.shop_id" type="primary" :disabled="!idList.length" class="mb10" size="mini" @click="hint('导出选中列表')">导出数据</el-button>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="申请时间" prop="sp_excellent_time" width="150"/>
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="性别" prop="sex" />
            <ElTableColumn label="评选位置"  prop="position_name" />
            <ElTableColumn label="疤痕原因"  prop="reason_name" />
            <ElTableColumn label="疤痕类型" prop="type_name" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="归属市场" prop="market_name" />
            <ElTableColumn label="评选结果" >
              <template slot-scope="{ row }">
                <span v-if="row.selection_results == 0">未开始</span>
                <span v-if="row.selection_results == 1">评选中</span>
                <span v-if="row.selection_results == 2">优秀案例</span>
                <span v-if="row.selection_results == 3">满意案例</span>
                <span v-if="row.selection_results == 4">未通过</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="审理人"  prop="reviewer_name" />
            <ElTableColumn label="审核时间"  prop="tg_excellent_time" width="150"/>
            <ElTableColumn label="操作"  fixed="right">
              <template
                slot-scope="{ row }"
              >
                <span class="theme" @click="tapBtn('detail',row.id)"> 查看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/Pagination'
import { readAdminMarketList } from '@/api/shop';
import { getCustomerShopList, ssettingList } from "@/api/client";
import { caseList,caseExport } from '@/api/case';
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  sp_excellent_time = '';
  customer_name = ''; // 名称
  position_id = ''; // 位置
  reason_id = ''; // 成因
  type_id = ''; // 类型
  shop_id = ''; // 门店
  market_id = ''; // 市场
  selection_results = ''; // 状态
  realname = ''; // 审理人
  tg_excellent_time = ''; 
}
class Options {
  position_id=[]
  reason_id=[]
  type_id=[]
  shop_id=[]
  market_id=[]
  selection_results = [
    { id: 0, name: '未开始' },
    { id: 1, name: '评选中' },
    { id: 2, name: '优秀案例' },
    { id: 3, name: '满意案例' },
    { id: 4, name: '未通过' }
  ]
}
export default {
  name: 'GoodsList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: ''
    }
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  mounted () {
    this.getOptions()
    this.getList()
  },
  methods: {
    // 获取选项
     getOptions() {
      ssettingList({page:1,limit:100000}).then(res => {
        let that= this
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.type_id.push(item);
          } else if ( item.category_id == 2){
           that.options.position_id.push(item);
        }else if ( item.category_id == 3){
           that.options.reason_id.push(item);
        }
      });
      });
      getCustomerShopList({ page: 1, limit: 1000000,type:0 }).then(res => {
        this.options.shop_id = res.data;
      });
      readAdminMarketList().then(res => {
        this.options.market_id = res.list;
      })
    },
    // 获取列表
    getList (option) {
      if (option === 'search') this.page = new Page();
      caseList({...this.search,...this.page,list_type:2}).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      })
    },
    // 点击详情
    tapBtn (type,id) {
       switch (type) {
        case 'detail':
           const query = {
             id: id,
             pageName: "案例列表",
             search: this.search,
             page: this.page
           };
           //this.$router.push({ name: "案例详情", query });

           let route = this.$router.resolve({ name: "案例详情", query });
           window.open(route.href, '_blank');
            // this.$router.push('./caseDetail?id=' + id)
          break
      }
    },
    // 提示
   hint(text){
       this.$confirm(`确定${text}此商品吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getExport();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 导出
    getExport() {
      caseExport({ id: this.idList,list_type:2 }).then(res => {
        window.open(res.data.url);
      });
    },

    // 改变表格选中状态
    handleSelectionChange (val) {
      this.idList = val.map(i => {
        return i.id
      })
    }

  }
}
</script>

<style lang="scss" scoped>

.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px  20px  3px 0 ;
}
.accountCon{
    background: #fff;
    padding: 10px 0px 80px;
}
</style>
